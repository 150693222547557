.checkedList li::before {
  content: "";
  display: block;
  background-image: url("/img/checked.png");
  width: 15px;
  height: 15px;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkedList li {
  @apply relative pl-6 my-2 md:my-6 text-base;
}

.squareList li::before {
  content: "";
  display: block;
  background-image: url("/img/buyingwith/marker.png");
  width: 15px;
  height: 15px;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}

.squareList li {
  @apply relative pl-6 my-6 text-base text-gray-700;
}
